import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';

export type PriorAuthLookupAuthFalseResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  any;

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupAuthFalseResults = ({
  fields,
  resultMessage,
  responseData,
}: PriorAuthLookupAuthFalseResultsProps) => {
  console.log('PriorAuthLookupAuthFalseResults fields = ', fields);
  return (
    <div className={classNames('text-left', 'pb-12', 'pt-8', 'px-12')}>
      {/* Heading Section */}
      <div className={classNames('pb-4', 'flex', 'items-center', 'space-x-4')}>
        <ImageWrapper
          field={{
            value: {
              src: resultMessage?.fields?.iconImage?.value?.src,
              width: '25px',
              height: '25px',
            },
          }}
        />
        <Heading
          headingText={{ value: resultMessage?.fields?.titleLabel?.value }}
          headingClassName={classNames('font-family')}
          headingLevel="h2"
          headingSize="md"
          descriptionClassName="mb-8"
          headingIsRichText
        />
      </div>
      {/* Group Number and CPT Code Section */}
      <div>
        <p className="mb-2">
          <span className="font-bold">Group Number:</span> {responseData?.GroupNumber}
        </p>
        <p>
          <span className="font-bold">CPT Code:</span> {responseData?.ProcedureCode?.CPTCode}
        </p>
      </div>

      {/* RichText Section */}
      <div className={classNames('rte', 'mb-4', 'mt-4')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthFalseResults;
