import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';

export type PriorAuthLookupAuthNullResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  any;

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupAuthNullResults = ({
  fields,
  resultMessage,
}: PriorAuthLookupAuthNullResultsProps) => {
  //const authNullResults = fields?.defaultResults as ResultMessages | undefined;
  //const defaultResultsTitle =
  // authNullResults?.fields?.titleLabel?.value || 'Results will appear here';

  // const defaultResultsText =
  // authNullResults?.fields?.messageText?.value ||
  //   'Prior authorization requirements for the group number and procedure code you enter will show up here.';

  //const iconImageSrc = fields?.iconImage?.value?.src;

  console.log('fields', fields);
  console.log('resultMessage', resultMessage);
  console.log('resultMessage?.fields?.titleLabel?.value', resultMessage?.fields?.titleLabel?.value);
  return (
    <div className={classNames('text-center', 'pb-16', 'pt-16')}>
      <div className={classNames('pb-6')}>
        <Heading
          //headingText={resultMessage?.fields?.titleLabel?.value}
          headingText={{ value: resultMessage?.fields?.titleLabel?.value }}
          headingClassName={classNames('font-family')}
          headingLevel="h2"
          headingSize="md"
          descriptionClassName="mb-12"
          headingIsRichText
        />
      </div>
      <div className={classNames('rte', 'px-12', 'mb-6', 'mt-6')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthNullResults;
