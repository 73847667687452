/* eslint-disable @next/next/no-img-element */
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import axios from 'axios';
import classNames from 'classnames';
import AutocompleteDropdown from 'components/helpers/AutocompleteDropdown/AutocompleteDropdown';
import Container from 'components/helpers/Container/Container';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { useEffect, useState, ChangeEvent } from 'react';
//https://react-tooltip.com/docs/getting-started
import { Tooltip } from 'react-tooltip';
import PriorAuthLookupAuthNullResults from './PriorAuthLookupAuthNullResults';
import PriorAuthLookupDefaultResults from './PriorAuthLookupDefaultResults';
import PriorAuthLookupAuthFalseResults from './PriorAuthLookupFalseResults';
export type PriorAuthLookupProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup;
export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages &
  Sitecore.Override.ItemEx[];

export type PriorAuthPlanType = Sitecore.Override.ItemEx &
  Feature.Data.Priorauth.PriorauthLookupOption.Fields.PriorauthLookupOption;

const PriorAuthLookup = ({ fields, rendering }: PriorAuthLookupProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  // State to manage the currently displayed component
  const [currentResultComponent, setCurrentResultComponent] = useState<string>('default');
  const [dateOfServiceProcedure, setDateOfServiceProcedure] = useState<string>('');
  const [memberId, setMemberId] = useState<string>('');
  const [disableSearchButton, setDisableSearchButton] = useState<boolean>(true);
  const [selectedCptCode, setSelectedCptCode] = useState<string>('');
  const [responseData, setResponseData] = useState<any>(null);

  //state management: plantype form component
  const [planTypeSelectionData, setPlanTypeSelectionData] = useState<
    PriorAuthPlanType | undefined
  >();

  //state management: plan type options
  const [selectedPlanType, setSelectedPlanType] = useState<string | null>(
    fields?.planType[0]?.name
  );

  const [resultMessage, setResultMessage] = useState<ResultMessages>();

  useEffect(() => {
    const isSearchButtonEnabled =
      memberId?.length > 0 && dateOfServiceProcedure?.length > 0 && selectedCptCode?.length > 0;
    setDisableSearchButton(!isSearchButtonEnabled);
  }, [memberId, dateOfServiceProcedure, selectedCptCode]);

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDateOfServiceProcedure(value);
  };

  const handleMemberIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMemberId(value);
  };

  const handleCptCodeAndDescription = (cptCode: string) => {
    setSelectedCptCode(cptCode);
  };

  const defaultResultMessages = fields.defaultResults as unknown as ResultMessages;

  const minHeight = 'min-h-[450px]';
  const searchIcon = fields?.searchIcon?.value?.src;

  console.log('resultMesssages===>', defaultResultMessages);

  const componentAnchorId = rendering?.uid;

  // priorauth-options-section logic
  useEffect(() => {
    setDefaultItem();
  }, []);

  function handelPlanTypeDataSelection(value: any) {
    const selectedPlanType: any = fields?.planType.filter((el) => el.name === value?.value);
    if (selectedPlanType != null || selectedPlanType != undefined) {
      setPlanTypeSelectionData(selectedPlanType[0]);
      setSelectedPlanType(value);
    }
  }

  function setDefaultItem() {
    const defaultSelection: any = fields?.planType.filter(
      (el) => el.name.toLowerCase() === 'select'
    );

    if (defaultSelection != null || defaultSelection != undefined) {
      setPlanTypeSelectionData(defaultSelection[0]);
      setSelectedPlanType(defaultSelection[0]?.name);
    }
  }

  function clearResults() {
    console.log('clearResults called');
  }

  const handleSearch = async () => {
    const requestBody = {
      requestSource: 'Member Portal',
      userType: selectedPlanType, // Change this to 'Provider' or 'Employer' to test different cases
      memberId: memberId,
      dateOfService: dateOfServiceProcedure,
      procedures: [
        {
          code: 'J0717',
        },
      ],
    };

    try {
      const response = await axios.post('/api/mockAuthorizationResponse', requestBody);
      const data = response?.data?.data;

      if (response.data?.success) {
        // Process and display the response data
        setResponseData(data);
        const authResultMessageList = planTypeSelectionData?.fields
          ?.authResultMessages as unknown as ResultMessages[];
        if (data.AuthRequired === null) {
          setCurrentResultComponent('authNull');
          const resultMessage = authResultMessageList?.find(
            (item) => item?.fields?.code?.value === '404'
          );
          setResultMessage(resultMessage);
        } else if (data.AuthRequired === true) {
          setCurrentResultComponent('authTrue');
          const resultMessage = authResultMessageList?.find(
            (item) => item?.fields?.code?.value === '200'
          );
          setResultMessage(resultMessage);
        } else if (data.AuthRequired === false) {
          setCurrentResultComponent('authFalse');
          const resultMessage = authResultMessageList?.find(
            (item) => item?.fields?.code?.value === '401'
          );
          setResultMessage(resultMessage);
        }
      }
    } catch (error) {
      console.error('Error fetching authorization data:', error);
    }
  };

  return (
    // the main container that has the theme
    <Container
      theme="theme-blue--light"
      dataComponent="authorable/priorauthlookup"
      className={classNames('')}
      id={componentAnchorId}
      fullWidth={true}
    >
      {/* the main element that controls the padding for all content */}
      <div
        className={classNames(
          'priorauth-content-body',
          'container-content-padding-x',
          'md:space-y-12',
          'space-y-8',
          'p-[35px]'
        )}
      >
        {/* the heading section of the form */}
        <section className={classNames('priorauth-heading')}>
          <h2 className={classNames('text-5xl leading-[70px] font-light')}>
            {fields?.priorAuthTitle?.value}
          </h2>
        </section>

        <section className={classNames('priorauth-options-section')}>
          {/* <label htmlFor={`${componentAnchorId}-selectLabel`}>{fields?.optionsLabel?.value}</label> */}

          <FormSelectList
            id={`${componentAnchorId}-selectLabel`}
            name={`${componentAnchorId}-selectLabel`}
            label={fields?.optionsLabel?.value}
            placeholder={undefined}
            className={classNames('max-w-[50%]')}
            options={fields?.planType.map((opt) => {
              return {
                value: opt.name,
                label: (opt as PriorAuthPlanType)?.fields?.optionTitle?.value,
              };
            })}
            defaultValue={{ value: fields?.planType[0]?.name, label: fields?.planType[0]?.name }}
            isSearchable={false}
            callBackChange={handelPlanTypeDataSelection}
            value={selectedPlanType}
          />
        </section>

        {/* the form section */}
        <div
          className={classNames(
            'priorauth-form-shell',
            'rounded-lg bg-white shadow-card',
            minHeight
          )}
        >
          <div
            className={classNames(
              'border-b border-gray border-l border-r  md:p-8 rounded-b-lg p-5 text-gray-dark border-t rounded-t-lg',
              minHeight
            )}
          >
            <div
              className={classNames(
                'priorauth-form-body',
                'grid',
                'grid-cols-1',
                'gap-4',
                'md:grid-cols-2',
                'md:gap-5',
                'lg:gap-12',
                'space-y-6',
                'md:space-y-0',
                'relative',
                'lg:p-[35px]',
                'flex',
                'divide-x',
                `divide-[#E6E6E6]`
              )}
            >
              <div className={classNames('mb-10', 'md:mb-0', 'space-y-6', 'px-6')}>
                <div>
                  <label className="flex mb-2">
                    {planTypeSelectionData?.fields?.dateOfServiceTitle?.value}
                    {planTypeSelectionData?.fields?.dateOfServiceTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.dateOfServiceTooltip?.value.length > 0 && (
                        <>
                          <i data-tooltip-id="dos-tooltip" className="ml-1">
                            <img
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                              className="h-[20px] w-[20px]"
                            />
                          </i>
                          <Tooltip
                            id="dos-tooltip"
                            className="text-theme-text bg-theme-bg tooltip"
                            html={planTypeSelectionData?.fields?.dateOfServiceTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>
                  <input
                    type="text"
                    className="w-full lg:w-[483px] h-[56px] border border-gray-200 rounded-md p-[16px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={dateOfServiceProcedure}
                    onChange={handleDateChange}
                    placeholder="MM/DD/YYYY"
                  />
                </div>
                <div className="pt-4 h-[60px]">
                  <label className="flex">
                    {planTypeSelectionData?.fields?.procedureKeywordTitle?.value}
                    {planTypeSelectionData?.fields?.procedureKeywordTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.procedureKeywordTooltip?.value.length > 0 && (
                        <>
                          <i data-tooltip-id="cpt-tooltip" className="ml-1">
                            <img
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                              className="h-[20px] w-[20px]"
                            />
                          </i>
                          <Tooltip
                            id="cpt-tooltip"
                            className="text-theme-text bg-theme-bg tooltip"
                            html={planTypeSelectionData?.fields?.procedureKeywordTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>{' '}
                  <div className="mt-2">
                    <AutocompleteDropdown
                      searchIcon={searchIcon}
                      handleCptCodeAndDescription={handleCptCodeAndDescription}
                    />
                  </div>
                </div>

                <div className="pt-[60px]">
                  <label className="mb-2 flex">
                    {planTypeSelectionData?.fields?.memberIdTitle?.value}
                    {planTypeSelectionData?.fields?.memberIdTooltip?.value !== undefined &&
                      planTypeSelectionData?.fields?.memberIdTooltip?.value?.length > 0 && (
                        <>
                          <i data-tooltip-id="member-id-tooltip" className="ml-1">
                            <img
                              className="h-[20px] w-[20px]"
                              src={planTypeSelectionData?.fields?.tooltipIcon?.value?.src}
                            />
                          </i>
                          <Tooltip
                            id="member-id-tooltip"
                            className="text-theme-text bg-theme-bg tooltip"
                            html={planTypeSelectionData?.fields?.memberIdTooltip?.value}
                            place="top"
                            offset={5}
                            openOnClick={true}
                          />
                        </>
                      )}
                  </label>
                  <input
                    type="text"
                    className="w-full lg:w-[483px] h-[56px] rounded-md p-[16px] focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter ID"
                    value={memberId}
                    onChange={handleMemberIdChange}
                  />
                </div>

                <div className="pt-4">
                  <button
                    onClick={handleSearch}
                    disabled={disableSearchButton}
                    className={`${
                      disableSearchButton
                        ? 'btn-disabled-priorauth-submit'
                        : 'btn-enabled-priorauth-submit'
                    } flex justify-center items-center h-[56px] w-[167px] px-4 py-2 bg-gray-500 text-black rounded-[20pc] hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400`}
                  >
                    {fields?.searchText?.value}
                    <svg
                      className="w-5 h-5 mr-2 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-4.35-4.35m1.35-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div>
                  <span>
                    <i>{fields?.searchDescription?.value}</i>
                  </span>
                </div>
              </div>
              <div
                className={classNames(
                  'prior-auth-results-section',
                  'mb-10',
                  'md:mb-0',
                  'space-y-6',
                  'px-6',
                  minHeight
                )}
              >
                <div
                  onClick={clearResults}
                  className={classNames(
                    'clear-prior-auth-results',
                    'flex justify-end',
                    'text-blue-peacock',
                    'text-base leading-[16px] font-regular',
                    'cursor-pointer'
                  )}
                >
                  {fields?.clearResultsTitle?.value}
                </div>
                {currentResultComponent === 'default' && (
                  <PriorAuthLookupDefaultResults fields={fields} rendering={rendering} />
                )}
                {currentResultComponent === 'authNull' && (
                  <PriorAuthLookupAuthNullResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                  />
                )}
                {currentResultComponent === 'authTrue' && (
                  <div>PriorAuthLookupAuthTrueResults component here</div>
                  // <PriorAuthLookupAuthTrueResults fields={fields} data={data} />
                )}
                {currentResultComponent === 'authFalse' && (
                  <PriorAuthLookupAuthFalseResults
                    fields={fields}
                    rendering={rendering}
                    resultMessage={resultMessage}
                    responseData={responseData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={classNames('priorauth-footer', 'pb-6')}>
          <RichTextA11yWrapper field={fields?.footerText}></RichTextA11yWrapper>
        </div>
      </div>
    </Container>
  );
};

export default PriorAuthLookup;
