import axios from 'axios';
import { CPTCodeRecord } from 'lib/server-utils/nexus-api';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';

const fetchCPTCodes = async (cptCodeOrLongDescription: string): Promise<any> => {
  console.log('CptCodeOrLongDescription ===>', cptCodeOrLongDescription);
  try {
    const ajaxResult = await axios.get(
      `https://nexusapi.dev.services.azblue.com:8593/api/Priorauth/GetProcedureCodes?cptCodeOrLongDescription=${cptCodeOrLongDescription}`
    );

    // Empty array if there's no result data
    const data = ajaxResult?.data ?? [];

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error to maintain the expected return type
  }
};

interface AutocompleteDropdownProps {
  searchIcon: string | undefined;
  handleCptCodeAndDescription: (cptCode: string) => void;
}

const AutocompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  searchIcon,
  handleCptCodeAndDescription,
}) => {
  const [val, setVal] = useState('');
  const [cptCode, setCPTCode] = useState<CPTCodeRecord[]>([]);
  const [loading, setLoading] = useState(false);

  const [showNoRecordsFound, setShowNoRecordsFound] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);

  useEffect(() => {
    console.log('cptCode===>', cptCode);
  }, [cptCode]);

  useEffect(() => {
    setShowNoRecordsFound(false);
    if (val && val.length > 2 && !itemSelected) {
      setLoading(true);
      fetchCPTCodes(val).then((response: CPTCodeRecord[]) => {
        // To take only first 20 results
        const filteredCpt: CPTCodeRecord[] = Array.isArray(response) ? response?.slice(0, 20) : [];
        console.log('data===>', response);
        setCPTCode(filteredCpt);
        setLoading(false);
        setShowNoRecordsFound(true);
      });
    } else {
      setCPTCode([]);
    }
  }, [val]);

  return (
    <>
      <div className="relative flex items-center h-[56px] w-full lg:w-[483px] autocomplete-wrapper">
        <Autocomplete
          value={val}
          items={cptCode}
          getItemValue={(item) => {
            const value = item.cptCode + ' - ' + item.description;
            handleCptCodeAndDescription(item.cptCode);
            return value.length > 46 ? value.slice(0, 43) + '...' : value;
          }}
          shouldItemRender={(item, val) =>
            item.cptCode?.includes(val?.toUpperCase()) ||
            item.description?.includes(val?.toUpperCase())
          }
          renderMenu={(items) => (
            <div className="dropdown">
              {items.length === 0 && showNoRecordsFound && !itemSelected ? (
                <div className="p-2">No matching results</div>
              ) : (
                items
              )}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? 'selected-item' : ''}`}
            >{`${item.cptCode} - ${item.description}`}</div>
          )}
          onChange={(_event, val) => {
            setItemSelected(false);
            setVal(val);
          }}
          onSelect={(val) => {
            setVal(val);
            setItemSelected(true);
            setShowNoRecordsFound(false);
          }}
          inputProps={{
            placeholder: 'Enter Procedure Keyword or CPT Code',
            className:
              'w-full p-[16px] h-[56px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500',
          }}
        />
        {loading ? (
          <div className="loading">loading...</div>
        ) : (
          // eslint-disable-next-line @next/next/no-img-element
          <img src={searchIcon} className="search-icon" />
        )}
      </div>
    </>
  );
};

export default AutocompleteDropdown;
